import {Component, Injectable, Input, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ApiRestService} from '../_service/api-rest.service';
import {ApiRestService as InterventiApiRestService} from '../../Interventi/_services/api-rest.service';
import {Filtri} from "../models/filtri";
import {CalendarEvent} from "calendar-utils";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Permission} from "../../Login/_guards/Permission";
import {Task} from "../models/Task";
import {CalendarItem} from "../models/calendar-item";
import {RmaEditComponent} from "../../rma/rma-edit/rma-edit.component";
import {EditScadenzaComponent} from "../../Scadenzario/edit-scadenza/edit-scadenza.component";
import {Scadenza} from "../../Scadenzario/Models/Scadenza";
import {ScadenzeStoreService} from "../../Scadenzario/_services/scadenze-store.service";
import {RmaStoreService} from "../../rma/_services/rma-store.service";
import {ApiRestService as ScadApirest} from "../../Scadenzario/_services/api-rest.service";
import {InterventoComponent} from "../../Interventi/intervento/intervento.component";
import {
  AttivitaEditComponent as FormazioneAttivitaEditComponent
} from "../../formazione/attivita-edit/attivita-edit.component";
import {AttivitaTaskEditComponent} from "../Attivita/attivita-edit/attivita-task-edit.component";
import {InterventiStoreService} from "../../Interventi/_services/interventi-store.service";
import {AziendeStoreService} from "../../Admin/_service/aziende-store.service";
import {FormazioneStoreService} from "../../formazione/_services/formazione-store.service";
import {PrimaNotaStoreService} from "../../Prima_Nota/_services/primaNota-store.service";
import {ValutazioniStoreService} from "../../Valutazioni/_service/valutazioni-store.service";
import {ActivatedRoute, Router} from "@angular/router";
import {EditAttivitaClienteComponent} from "../../Admin/edit-attivita-cliente/edit-attivita-cliente.component";
import {environment} from 'src/environments/environment';
import {DatePipe} from "@angular/common";
import {ClassicType} from "../../shared/models/ClassicType";
import {Persona} from "../../Admin/models/persona";
import {Paginazione} from "../../shared/models/paginazione";
import {PersonaleStoreService} from "../../Admin/_service/personale-store.service";
import {FileStoreService} from "../../shared/_services/file-store.service";
import {RmaProdottoAddComponent} from "../../rma/rma-add/rma-prodotto-add.component";
import {Rma} from "../../rma/_Models/rma";
import {EditEsitiRmaComponent} from "../../rma/edit-esiti-rma/edit-esiti-rma.component";
import {CampoAggiuntivo, CampoUtility} from "../../shared/models/campo-aggiuntivo";
import {Color, hsl} from "d3";
import {IDropdownSettings} from "../../../LibrerieCustom/MultiselectDropdown";
import {Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {EditAttivitaRmaComponent} from "../../rma/edit-attivita-rma/edit-attivita-rma.component";
import {AuthenticationService} from "../../Login/_services/authentication.service";
import {NewsComponent} from "../news/news.component";
import {MultimediaCourse} from "../../formazione/_Models/multimedia-course";
import { Azienda } from 'src/app/Admin/models/Azienda';
import { ApiPathsService } from 'src/app/api-paths.service';
import { TaskService } from '../_service/task.service';
import { ServerPaginationService } from 'src/app/shared/_services/server-pagination.service';
@Injectable({ providedIn: 'root'})
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls:['./list.component.scss']
})
export class LISTComponent implements OnInit {
  environment = environment;
  today = new Date();
  openFilter: boolean = false;
  subscriptions: Subscription = new Subscription();
  @Input() filtro: Filtri = new Filtri(1, 200, undefined, environment.TaskAddon['periodo']!= undefined? false : true, undefined, undefined, this.today.getFullYear(), 13);
  scadenze: Task[] = [];
  collectionSize: number = 0;
  events: CalendarEvent[] = [];
  Stampe: any[];
  Aperte: number = 0;
  filtrife: ClassicType[] = environment.filtrife;
  subFilter: any[];
  @Input() persona: number;
  @Input() DisableEdit: boolean = false;
  @Input() cliente: number;
  @Input() clienteObject: Azienda;
  @Input() hideFiltriFe: boolean;
  @Input() filtrifeSel: ClassicType[] = [{id: "T", label: "Tutti"}]
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField: 'id',
    textField: 'label',
  };
  subdropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField: 'id',
    textField: 'label',
  };
  userdropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField: 'id',
    textField: 'fullName',
    searchPlaceholderText: 'Cerca',
    allowSearchFilter: true
  };
  Persone: Persona[] = [];
  PersoneSelezionate: Persona[] = [];
  scadute: boolean = false;
  @Input() subFilterSel: any[] = [];
  hideFilter: boolean = false;
  @Input() title: string;
  titolo: string;
  fromDashboard: boolean = false;
  hasAddScadenza: boolean = false;
  hideAddButton: boolean = false;
  @Input() type: string;
  @Input() filter: string;
  @Input() filtri: any[];
  @Input() headers: any[];
  bup: any[];
  private ordine: { field: string, order: 'asc' | 'desc' } = {field: '', order: 'asc'};
  View: "Horizontal" | "Vertical" = "Horizontal";
  DynamicTaskSettings: any;
  paginationValue = {
    page: 1,
    pageSize: 25,
    pagesizeserver: 2,
    newpagegenerated: 1,
    total: 0,
  }

  constructor(
    private ApiRestService: ApiRestService,
    private apiPaths: ApiPathsService,
    public permission: Permission,
    public scadenzeStore: ScadenzeStoreService,
    private modalService: NgbModal,
    private RmaStore: RmaStoreService,
    private ScadenzeApiRestService: ScadApirest,
    private InterventiStore: InterventiStoreService,
    private clientiStore: AziendeStoreService,
    private formazioneStore: FormazioneStoreService,
    private primanotaStore: PrimaNotaStoreService,
    private route: ActivatedRoute,
    private router: Router,
    public campoUtility: CampoUtility,
    private InterventiApiRestService: InterventiApiRestService,
    private personaleStore: PersonaleStoreService,
    private datepipe: DatePipe,
    private ValutazioniStoreService: ValutazioniStoreService,
    private FileStore: FileStoreService,
    private titleService: Title,
    private translate: TranslateService,
    private AuthService: AuthenticationService,
    private TaskService: TaskService,
    private paginationService: ServerPaginationService,
  ) {
  }

  headShow(campo): boolean {
    if (!campo['filtertype'] && !campo['filternottype'])
      return true;
    var filterType = !campo['filtertype'] || (!Array.isArray(campo['filtertype']) && (campo['filtertype'] == this.type || campo['filtertype'] == this.filter)) || (Array.isArray(campo['filtertype']) && campo['filtertype']?.some(x => x == this.type || x == this.filter))
    var filternotType = !campo['filternottype'] || (!Array.isArray(campo['filternottype']) && campo['filternottype'] != this.type && campo['filternottype'] != this.filter) || (Array.isArray(campo['filternottype']) && (campo['filternottype']?.every(x => x != this.type && x != this.filter)))
    return filterType && filternotType;
  }

  hasListActions(){
    return this.DynamicTaskSettings && this.DynamicTaskSettings['AzioniLista']?.filter(x=>!x.single)?.length > 0;
  }
  allSelected(headerField: any, data: any[]){
    return data.every(x=> x[headerField]);
  }

  toggleAll(headerField: any, data: any[]){
    var oldValue = Object.assign({}, {val: this.allSelected(headerField, data)})
    data.forEach((x)=> x[headerField] = !oldValue.val)
  }

  dynamicHeader(){
    var task = this.campoUtility.getDynamicTaskSctructure(this.type, this.filter);
    if(this.headers?.length >0)
      return this.headers;
    else if(task && task['Headers']?.length >0 )
      return task['Headers'];
    else if (environment.TaskAddon['ListTableHeaderField'])
      return environment.TaskAddon['ListTableHeaderField'];
    else null;
  }
  IsArray(val){
    return val && Array.isArray(val);
  }

  getAddButtons(){
    var task = this.campoUtility.getDynamicTaskSctructure(this.type, this.filter);
    var fields = this.environment.TaskAddon['DynamicFields']?.filter(campo => campo.addButton && (!task || campo.type == task.type) && (!this.filter || this.filter == '0' || !campo['tipo'] || campo['tipo']  == this.filter) && this.campoUtility.Show(campo, this,false));

    return fields ?? [];
  }

  hasbuttons(){
    return this.getAddButtons()?.length > 0 && !this.hideAddButton && ((!this.cliente && !this.persona) || this.campoUtility.getDynamicTaskSctructure(this.type, this.filter));
  }
  addDynamicButton(dynamicField) {
    const editmodal = this.modalService.open(NewsComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    (<NewsComponent>editmodal.componentInstance).DynamicFields = dynamicField;
    (<NewsComponent>editmodal.componentInstance).IsModal = true;
    (<NewsComponent>editmodal.componentInstance).Item = {cliente: this.cliente, clienteObject: this.clienteObject, tipo: this.filter == '0' ? undefined: this.filter};
    (<NewsComponent>editmodal.componentInstance).updated.subscribe(data => {
      this.Refresh();
    });
  }

  ngOnInit() {
    this.route.params.subscribe(data => {
      if(data['tipo']?.length >0  && data['filter']?.length >0){
        this.type = data['tipo'] ?? this.type;
        this.filter = data['filter'] ?? this.filter;
        this.View = data['view'] ?? this.View;
      }
      this.hasAddScadenza = (!this.type || this.type == 'T' || this.type == 'S') && !this.persona;
      this.route.url.subscribe(data => {
        this.fromDashboard = data.find(x => x.path.includes("dashboard")) != undefined;
        if (this.fromDashboard) {
          this.filtro['tipoScaduti'] = JSON.parse(localStorage.getItem('filtriDashboard'))['tipoScaduti'];
          this.filtro['dataScaduti'] = JSON.parse(localStorage.getItem('filtriDashboard'))['dataScaduti'];
          this.filtro['provincia'] = JSON.parse(localStorage.getItem('filtriDashboard'))['provincia'];
          this.filtro['mese'] = 13;
          this.filtro.periodo = false;
        }
      })
      this.hideFilter = this.type != undefined && this.filter != undefined;
      this.filtro.tutti = (this.permission.hasOperativeRoles(['AZ', 'RT']) || this.AuthService?.currentUserValue?.ruoliAccessoObject?.find(x=> x.ruolo?.toUpperCase() === 'RT') != undefined|| this.AuthService?.currentUserValue?.ruoliAccessoObject?.find(x=> x.ruolo?.toUpperCase() === 'Amministrazione'.toUpperCase()) != undefined|| this.AuthService?.currentUserValue?.ruoliAccessoObject?.find(x=> x.ruolo?.toUpperCase() === 'SP') != undefined|| this.AuthService?.currentUserValue?.ruoliAccessoObject?.find(x=> x.ruolo?.toUpperCase() === 'Commerciale'.toUpperCase()) != undefined|| this.AuthService?.currentUserValue?.ruoliAccessoObject?.find(x=> x.ruolo?.toUpperCase() === 'All'.toUpperCase()) != undefined) ;
      this.filtro.hideCliente = this.cliente != undefined;


      if (this.hideFilter) {
        var typeObj = environment.filtrife.find(x => x.id.toLowerCase() == this.type.toLowerCase()) ?? {id: this.type};
        this.filtrifeSel = typeObj ? [typeObj] : [];
        var filterObj = typeObj['SubFilter']?.find(x => x.id.toLowerCase() == this.filter.toLowerCase()) ?? {id: this.filter};
        this.subFilter = typeObj['SubFilter'] ?? [];
        this.subFilterSel = filterObj ? [filterObj] : [];
        console.log('filterObj', filterObj);
        console.log('typeObj', typeObj);
        console.log('filtrifeSel', this.filtrifeSel);
        console.log('subFilterSel', this.subFilterSel);
      }
      this.route.queryParams
        .subscribe(params => {
            Object.getOwnPropertyNames(params)?.forEach(prop => {
              this.filtro[prop] = params[prop];
              console.log('copy ' + params[prop] + ' to ' + 'this.filtro.' + prop)
            })
            this.setPaginationFilter( 1)
            this.setPersonaFilter();
            this.setStatoFilter();
            this.setTipoFilter();
            this.setDynamicTaskSettings();
            if(this.DynamicTaskSettings && this.DynamicTaskSettings['defaultFilter'])
              this.filtro = Object.assign(this.filtro, this.DynamicTaskSettings['defaultFilter']);
            this.setAdditionalFilters();
            this.fetchCalendarData();
            this.fetchPersonaleList();


          }
        );
      /*this.Refresh();*/
    })

  }

  LightenColor(color: Color, percent = 50) {
    var num = parseInt(color.toString().replace("#", ""), 16);
    if (num < 16750000)
      return hsl(0, 0, 100);
    else
      return hsl(0, 0, 0);
  };

  Refresh(page?: number) {
    this.setPaginationFilter(page ?? 1)
    this.setPersonaFilter();
    this.setStatoFilter();
    this.setTipoFilter();
    this.setDynamicTaskSettings();
    this.setAdditionalFilters();
    this.fetchCalendarData();
    this.fetchPersonaleList();
  }

  setPaginationFilter(page?: number) {
    if (!page) return;
    this.filtro.page = ((page ?? 1) - 1) / this.paginationValue.pagesizeserver + 1;
    this.filtro.size = this.paginationValue.pagesizeserver * this.paginationValue.pageSize;
  }

  // Imposta il filtro persona se necessario
  private setPersonaFilter() {
    if (!environment.TaskAddon['sidebarFilter']) {
      this.filtro.persona = this.PersoneSelezionate?.find(x => true)?.id;
    }
  }

  // Imposta il filtro dello stato se selezionato
  private setStatoFilter() {
    if (this.subFilterSel?.length > 0 && this.subFilterSel[0].id !== "T") {
      this.filtro.stato = this.subFilterSel[0].id;
    }
  }

  // Imposta il filtro del tipo se selezionato
  private setTipoFilter() {
    if (this.filtrifeSel?.length > 0 && this.filtrifeSel[0].label && this.filtrifeSel[0].id !== "T" && !this.filtrifeSel[0]['noFilter']) {
      this.filtro.tipo = this.filtrifeSel[0].id;
    }
    if (this.filtrifeSel?.length > 0 && this.filtrifeSel[0]['tipo'] !== "T" && !this.filtrifeSel[0]['noFilter']) {
      this.filtro.tipo = this.filtrifeSel[0]['tipo'];
      this.filtro['subType'] = this.filter;
    }
  }

  // Imposta le impostazioni dinamiche del task
  private setDynamicTaskSettings() {
    this.DynamicTaskSettings = this.campoUtility.getDynamicTaskSctructure(this.filtro.tipo, this.filtro['subType'] &&  this.filtro['subType'] != 0 &&  this.filtro['subType'] != '0' ?  this.filtro['subType']: null);
    console.log("DynamicTaskSettings: ", this.DynamicTaskSettings);
  }

  // Imposta eventuali filtri aggiuntivi
  private setAdditionalFilters() {
    if (this.persona) {
      this.filtro.persona = this.persona;
    }
    if (this.cliente) {
      this.filtro.cliente = this.cliente;
    }
  }

  // Esegue la chiamata per ottenere i dati del calendario
  private fetchCalendarData() {
    this.subscriptions.add(
      this.ApiRestService.GetCalendarData(this.filtro, false).subscribe(data => {
        if (data) {
          this.processCalendarData(data);
        }
      })
    );
  }

  // Processa i dati del calendario
  private processCalendarData(data: any) {
    const filtro = this.filtrife?.length > 0 && this.filtrifeSel[0]?.id !== "T";

    data.data = data.data?.map(x => {
      if (x.type === 'W') {
        x.clienteObject = x.clienteObject ?? x.padreObject?.clienteObject;
      }
      return x;
    });

    const sel = this.subFilterSel.find(x => true);
    let taskList = !filtro ? data.data : data?.data?.filter(x => x.type === (this.filtrifeSel[0]['tipo'] ?? this.filtrifeSel[0].id) && x.abilitata !== false);

    this.updateTitle();
    // taskList = taskList.filter(x => this.campoUtility.Show({ hideif: sel, modificabile: true }, x, true) &&
    //                                 this.campoUtility.Show({ hideif: sel?.hideif, modificabile: true }, x, true));
    taskList = taskList.filter(x => (!sel?.hideif && this.campoUtility.CheckValue(sel, x) ) || (sel?.hideif && this.campoUtility.Show({ hideif: sel?.hideif, modificabile: true }, x, true)));

    this.scadenze = this.splitTaskListIfNecessary(taskList, filtro);
    this.bup = Object.assign([], this.scadenze);
    this.collectionSize = filtro ? this.scadenze.length : data.total;
    this.paginationValue.total = data.total;
    this.Stampe = data.stampe;
    this.Aperte = data.scadenze;
  }

  // Aggiorna il titolo della pagina
  private updateTitle() {
   var newTitle = this.subFilterSel?.length > 0 && this.subFilterSel[0].label && this.subFilterSel[0].id !== "T"
    ? this.translate.instant(this.subFilterSel[0].label)
    : (this.filtrifeSel[0].label && this.filtrifeSel[0].id !== "T"
      ? this.translate.instant(this.filtrifeSel[0].label)
      : (environment.TaskAddon['listTitle'] ?? "Lista"));
    this.titolo = this.title ?? newTitle;

    this.titleService.setTitle(this.titolo);
  }

  // Divide la lista di task se necessario
  private splitTaskListIfNecessary(taskList: any[], filtro: boolean) {
    if (filtro && this.filtrifeSel[0]['splitBy']) {
      const splitted = [];
      taskList.forEach(task => {
        task[this.filtrifeSel[0]['splitBy']].forEach(x => {
          const out = {};
          out[this.filtrifeSel[0]['splitBy']] = [x];
          this.filtrifeSel[0]['copyParam']?.forEach(param => {
            if (typeof param === 'string') {
              out[param] = task[param];
            } else if (param?.fromsplitted) {
              out[param.out] = x[param?.fromsplitted];
            } else if (param?.fromsource) {
              out[param.out] = task[param?.fromsource];
            }
          });
          splitted.push(out);
        });
      });
      return splitted;
    }
    return taskList;
  }

  // Ottiene la lista del personale se l'utente ha il ruolo operativo 'AZ'
  private fetchPersonaleList() {
    if (this.permission.hasOperativeRole('AZ')) {
      this.personaleStore.getListaPersonale(new Paginazione(0, 100));
    }
    this.subscriptions.add(
      this.personaleStore.$listaPersonale.subscribe(data => {
        this.Persone = data?.data;
      })
    );
  }

  filtrifechng() {
    if (this.hideFilter) return;
    if (!this.filtrifeSel || this.filtrifeSel.length == 0 || !this.filtrifeSel[0]) {
      this.filtrifeSel = [{id: "T", label: "Tutti"}]
    }
    this.titleService.setTitle(this.filtrifeSel[0].label && this.filtrifeSel[0].id != "T" ? this.translate.instant(this.filtrifeSel[0].label) : "Lista");
    this.subFilterSel = [];
    this.subFilter = this.filtrifeSel[0]['SubFilter'] ?? [];
    this.Refresh()
  }

  subFiltrifechng() {
    var sel: {
      id: string | number,
      label: String,
      capacita: [],
      module: string,
      fieldnotexist: string[],
      fieldexist: string[]
    } = this.subFilterSel.find(x => true);
    console.log(sel)
    this.Refresh();
    // this.subscriptions.add(this.ApiRestService.GetCalendarData(this.filtro,false).subscribe(data => {
    //   var filtro: boolean = this.filtrife?.length > 0 && this.filtrifeSel[0]?.id != "T";
    //   if (data) {
    //     this.scadenze = filtro ? data?.data?.filter(x=>x.type == (this.filtrifeSel[0]['tipo'] ?? this.filtrifeSel[0].id ) && x.abilitata != false): data.data;
    //     this.scadenze = this.scadenze.filter(x=> {
    //       var found = true;
    //       if((!sel?.fieldexist && !sel?.fieldnotexist))return true;
    //       if(sel?.fieldexist)
    //         found = found && sel?.fieldexist?.every(exist => x[exist]);
    //       if(sel?.fieldnotexist)
    //         found = found && (sel.fieldnotexist?.find(notExist => !x[notExist]) != undefined)
    //       return found;
    //       // (!sel.fieldexist && !sel.fieldnotexist) || (sel?.fieldexist && x[sel?.fieldexist]) || (sel?.fieldnotexist && !x[sel?.fieldnotexist])
    //     } );
    //     this.collectionSize = filtro ? this.scadenze.length : data.total;
    //     this.Stampe = data.stampe;
    //     this.Aperte= data.scadenze;
    //   }
    // }));

  }

  ModFilter(mese: number) {
    this.scadute = false;
    this.filtro.mese = mese + 1;
    this.filtro.inizio = this.datepipe.transform(new Date(this.filtro.anno, mese == 12 ? 0 : mese, 1), 'yyyy-MM-dd');
    this.filtro.fine = this.datepipe.transform(new Date(this.filtro.anno, mese == 12 ? mese : mese + 1, 0), 'yyyy-MM-dd');
    this.Refresh();
  }

  DeleteButton(event) {
    if (!isNaN(<number>event.id))
      switch (event.type.toLocaleUpperCase()) {
        case "S":
          if (confirm("Sei sicuro di voler eliminare questo elemento? ")) {
            this.ScadenzeApiRestService.deleteScadenza(event).subscribe(data => {
              this.Refresh()
            });
          }
      }
  }

  getTaskTitle(task: Task): string {
    switch (task.type.toLocaleUpperCase()) {
      case 'R':
        return "Ticket #" + ((task?.progressivo ?? task.id) ? (task?.progressivo ?? task.id)?.toString() : "") + " " + (task.clienteObject?.nominativo + " - " + task.nome ?? '')
      case 'W':
        return task.padreObject?.clienteObject?.nominativo + " - " + task.nome;
    }
    return task.nome;
  }

  Warning(item: Task): { text: string, visible: boolean } {
    if (item.type.toUpperCase() != 'R' && item.type.toUpperCase() != 'W') return;
    var output = {
      visible: item.type.toUpperCase() == 'R' ? item.figli?.some(x => x.manager?.id == this.AuthService.currentUserValue.id && !x.data_fine_effettiva) : item.manager?.id == this.AuthService.currentUserValue.id && !item.data_fine_effettiva,
      text: item.type.toUpperCase() == 'R' ? 'Sono presenti Attivita in sospeso:\n' : 'Attivita non completata'
    }
    if (item.type.toUpperCase() == 'R')
      item.figli?.filter(x => x.manager?.id == this.AuthService.currentUserValue.id && !x.data_fine_effettiva)?.forEach(x => {
        output.text = `${output.text} ${x.nome}\n`;
      })
    return output;
  }

  WarningNoResp(item: Task): { text: string, visible: boolean } {
    if (item.type.toUpperCase() != 'R' && item.type.toUpperCase() != 'W') return;
    var output = {
      visible: item.type.toUpperCase() == 'R' ? item.figli?.some(x => !x.manager?.id && !x.data_fine_effettiva) : !item.manager?.id && !item.data_fine_effettiva,
      text: item.type.toUpperCase() == 'R' ? 'Sono presenti Attivita non Assegnate:\n' : 'Questa Attivita non è Assegnata'
    }
    if (item.type.toUpperCase() == 'R')
      item.figli?.filter(x => !x.manager?.id && !x.data_fine_effettiva)?.forEach(x => {
        output.text = `${output.text} ${x.nome}\n`;
      })
    return output;
  }

  tag(item: Task): { text: string, visible: boolean } {
    if (item.type.toUpperCase() != 'R' && item.type.toUpperCase() != 'W') return {text: '', visible: false};
    var output = {
      visible: item.type.toUpperCase() == 'R' ? item.figli?.some(x => x?.note?.includes('@' + this.AuthService.currentUserValue.username) && !x.data_fine_effettiva) : item?.note?.includes('@' + this.AuthService.currentUserValue.username) && !item.data_fine_effettiva,
      text: item.type.toUpperCase() == 'R' ? 'Sei stato/a taggato/a nelle seguenti Attivita:\n' : 'Sei stato/a taggato/a in questa Attivita'
    }
    if (item.type.toUpperCase() == 'R')
      item.figli?.filter(x => x?.note?.includes('@' + this.AuthService.currentUserValue.username) && !x.data_fine_effettiva)?.forEach(x => {
        output.text = `${output.text} ${x.nome}\n`;
      })
    return output;
  }


  EditButton(event: Task) {
    console.log(event)
    let editmodal;
    if(isNaN(<number>event.id)) return;
    var dynamicFields = this.campoUtility.getDynamicTaskSctructure(this.type, this.filter);
    if (!dynamicFields)
      switch (event.type.toLocaleUpperCase()) {
        case "R":

          if (environment.Modules['taskboard']) {
            editmodal = this.modalService.open(AttivitaTaskEditComponent, {
              centered: true,
              backdrop: 'static',
              size: 'xl',
            });
            (<AttivitaTaskEditComponent>editmodal.componentInstance).IsModal = true;
            (<AttivitaTaskEditComponent>editmodal.componentInstance).Item = event;
            (<AttivitaTaskEditComponent>editmodal.componentInstance).updated.subscribe(data => {
              this.Refresh()
            });
          } else {
            this.RmaStore.updateSelected({id: <number>event.id});
            editmodal = this.modalService.open(RmaEditComponent, {
              centered: true,
              backdrop: 'static',
              size: 'xl',
            });
            (<RmaEditComponent>editmodal.componentInstance).IsModal = true;
            (<RmaEditComponent>editmodal.componentInstance).added.subscribe(data => {
            });
            (<RmaEditComponent>editmodal.componentInstance).updated.subscribe(data => {
              this.Refresh()
            });
          }

          break;
        case "S":
          this.scadenzeStore.updateScadenzaSelezionata({id: <number>event.id});
          editmodal = this.modalService.open(EditScadenzaComponent, {
            centered: true,
            backdrop: 'static',
            size: 'xl',
          });
          (<EditScadenzaComponent>editmodal.componentInstance).IsModal = true;
          (<EditScadenzaComponent>editmodal.componentInstance).added.subscribe(data => {
          });
          (<EditScadenzaComponent>editmodal.componentInstance).updated.subscribe(data => {
            this.Refresh()
          });
          break;
        case "F": //fasi
          // this.InterventiStore.updateInterventoSelezionato({id: <number>event.id});
          this.InterventiStore.updateEditable(true);
          this.InterventiStore.updateShowAddForm(false);
          this.InterventiApiRestService.getInfoIntervento(event).subscribe(data => {
            const modal = this.modalService.open(InterventoComponent, {
              centered: true,
              backdrop: 'static',
              size: 'xl',
            });
            (<InterventoComponent>modal.componentInstance).InterventoSelezionato = data.data;
            (<InterventoComponent>modal.componentInstance).Refresh.subscribe(data => {
              this.Refresh()
            });
          });
          break;
        case "A":
          let attmodal = this.modalService.open(AttivitaTaskEditComponent, {
            centered: true,
            backdrop: 'static',
            size: 'xl',
          });
          (<AttivitaTaskEditComponent>attmodal.componentInstance).IsModal = true;
          (<AttivitaTaskEditComponent>attmodal.componentInstance).Item = event ?? new Task();
          (<AttivitaTaskEditComponent>attmodal.componentInstance).updated.subscribe(data => {
            this.Refresh()
          });
          break;
        case "N":
          let Newsmodal = this.modalService.open(NewsComponent, {
            centered: true,
            backdrop: 'static',
            size: 'xl',
          });
          (<NewsComponent>Newsmodal.componentInstance).DynamicFields = {
            type: 'N',
            addButton: true,
            restRoute: '/task/news',
            Label: 'News',
            campi:[
              {nome:'Titolo:', oggetti: ['nome'], modificabile:true ,inputType:'text', class: 'col-lg-3' },
              {nome:'Data:', oggetti: ['data_inizio_prevista'], modificabile:true ,inputType:'datetime-local', class: 'col-lg-3' },
              {nome:'Data Fine:', oggetti: ['data_fine_prevista'], modificabile:true ,inputType:'datetime-local', class: 'col-lg-3' },
              {nome:'Note:', oggetti: ['note'], inputType: 'html', modificabile: true, class: 'col-lg-12'},
            ],
          };
          (<NewsComponent>Newsmodal.componentInstance).IsModal = true;
          (<NewsComponent>Newsmodal.componentInstance).Item = event ?? new Task();
          (<NewsComponent>Newsmodal.componentInstance).updated.subscribe(data => {
            this.Refresh()
          });
          break;
        case "L": //lezioni
          this.formazioneStore.updateAttivita({id: <number>event.id});
          editmodal = this.modalService.open(FormazioneAttivitaEditComponent, {
            centered: true,
            backdrop: 'static',
            size: 'xl',
          });
          break;
        case "B": //spese
          this.primanotaStore.updateEditable(true);
          this.primanotaStore.updateAddable(false);
          this.primanotaStore.updateSpesaSelezionata({id: <number>event.id});
          this.router.navigate([this.route.snapshot.queryParams[' primanota/spesa '] || 'primanota/spesa',]);
          break;
        case "I": //fatture
          this.primanotaStore.updateEditable(true);
          this.primanotaStore.updateAddable(false);
          this.primanotaStore.updateFatturaSelezionata({id: <number>event.id});
          this.router.navigate([this.route.snapshot.queryParams[' primanota/fattura'] || 'primanota/fattura',]);
          break;
        case "V": //valutazione
          this.ValutazioniStoreService.updateSelected(event);
          // this.FileStore.SelectId(event.id);
          // this.FileStore.updateSelectedUrl('/task/task/file');
          this.FileStore.SelectSection('valutazione');
          this.router.navigate([this.route.snapshot.queryParams['valutazioni/editor'] || 'valutazioni/editor',]);
          break;
        case "W": //Segnalazione (sottotask rma)
          if (environment.RmaAddOn['CampiEsito']) {
            const editmodalw = this.modalService.open(EditEsitiRmaComponent, {
              centered: true,
              backdrop: 'static',
              size: 'xl',
            });
            (<EditEsitiRmaComponent>editmodalw.componentInstance).IsModal = true;
            (<EditEsitiRmaComponent>editmodalw.componentInstance).Item = event;
            (<EditEsitiRmaComponent>editmodalw.componentInstance).checkSelezionate = [];
            (<EditEsitiRmaComponent>editmodalw.componentInstance).added.subscribe(data => {
            });
            (<EditEsitiRmaComponent>editmodalw.componentInstance).updated.subscribe(data => {
              this.Refresh()
            });
          } else {
            const editmodalw = this.modalService.open(EditAttivitaRmaComponent, {
              centered: true,
              backdrop: 'static',
              size: 'xl',
            });
            (<EditAttivitaRmaComponent>editmodalw.componentInstance).IsModal = true;
            (<EditAttivitaRmaComponent>editmodalw.componentInstance).Item = event;
            (<EditAttivitaRmaComponent>editmodalw.componentInstance).updated.subscribe(data => {
              this.Refresh()
            });
          }
          break;
        case "C": //attivita cliente (CRM)
          this.clientiStore.updateSelectedAttivita({id: <number>event.id})
          this.modalService.open(EditAttivitaClienteComponent, {
            centered: true,
            backdrop: 'static',
            size: 'xl',
          });
          break;
      }
    else {
      let Newsmodal = this.modalService.open(NewsComponent, {
        centered: true,
        backdrop: 'static',
        size: 'xl',
      });
      (<NewsComponent>Newsmodal.componentInstance).DynamicFields = dynamicFields;
      (<NewsComponent>Newsmodal.componentInstance).IsModal = true;
      (<NewsComponent>Newsmodal.componentInstance).Item = event ?? new Task();
      (<NewsComponent>Newsmodal.componentInstance).updated.subscribe(data => {
        this.Refresh()
      });
    }
  }

  AggiungiScadenza() {
    this.scadenzeStore.updateScadenzaSelezionata(new Scadenza());
    const editmodal = this.modalService.open(EditScadenzaComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    (<EditScadenzaComponent>editmodal.componentInstance).IsModal = true;
    (<EditScadenzaComponent>editmodal.componentInstance).added.subscribe(data => {
      this.Refresh();
    });
    (<EditScadenzaComponent>editmodal.componentInstance).updated.subscribe(data => {
    });
  }
  addNews() {
    const editmodal = this.modalService.open(NewsComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    (<NewsComponent>editmodal.componentInstance).IsModal = true;
    (<NewsComponent>editmodal.componentInstance).Item = {};
    (<NewsComponent>editmodal.componentInstance).updated.subscribe(data => {
      this.Refresh();
    });
  }

  GetScadute() {
    this.filtro.mese = 14;
    this.scadute = true;
    this.filtro.inizio = "2020-01-01";
    this.filtro.fine = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.filtro.chiuse = false;
    this.Refresh();
  }


  // Klima- inserire un parametro "Standby" per rendere invisibile il prodotto nell'ecommerce
  // Klima- Rendere facoltativo il prezzo del prodotto
  // Klima- ordinare la lista dei prodotti dentro la categoria(drag)
  // Klima- ordinare le categorie(parametro ordine)
  // Klima- far visualizzare i prodotti delle categorie secondarie nella categoria principale


  AggiungiRma() {
    const editmodal = this.modalService.open(RmaProdottoAddComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    const _newRma = new Rma();
    // _newRma.data_inizio_prevista = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.RmaStore.updateSelected(_newRma);
    (<RmaProdottoAddComponent>editmodal.componentInstance).onClose.subscribe(data => {
      this.Refresh();
    })
  }


  order(campo: CampoAggiuntivo) {
    var order: 'asc' | 'desc' = campo.nome == this.ordine.field ? (this.ordine.order == 'asc' ? 'desc' : 'asc') : 'asc'

    this.scadenze = this.campoUtility.getSortedList(this.bup, campo, order);
    this.ordine = {field: campo.nome, order: order}
  }

  Segui(item: Task) {
    this.Seguimulti(item['multimediaObject'], item)
  }
  Seguimulti(multimediaObject: MultimediaCourse[], lex) {
    var item = multimediaObject.find(x=>true);
    if(!item) return;
    this.openLezione(item, lex);
  }
  openLezione(item: MultimediaCourse,lex) {
    var type = item.tipoObject.id.toString().toUpperCase();
    var _url = 'formazione/' + (type == "V" ? 'video' : (type == "P" ? 'pdf': (type == "Q" ? 'questionario' : '') )) + '/' + item.id.toString() + (lex ? '/'+ lex?.id : '')
    console.log(_url);
    this.router.navigate( [this.route.snapshot.queryParams[_url] || _url,]).then();
  }

  RowClick(item: Task) {
    if(this.DisabledTask(item) || this.DisableEdit) return;
    if(item.type == 'L' && item['multimediaObject']?.length == 1 && !this.permission.isPermitted('gestione-formazione'))
      this.Segui(item)
    else
      this.EditButton(item);
  }

  DisabledTask(item: Task) {
    return  this.DisableEdit || (item.type == 'L' && !this.permission.isPermitted('gestione-formazione') && item.abilitata != 1 && item.abilitata != true) ||
    (item.type == 'R' && !this.permission.isPermitted('gestione-rma'));
  }
  TaskTooltip(item: Task) {
    return this.DisabledTask(item) && item.type == 'L'? `Per accedere a questo contenuto devi completare la lezione "${item.padreObject?.fullName}"`:''
  }
  HandleTasksCustomButtonClick(button){
    this.TaskService.HandleTasksCustomButtonClick(this.scadenze.filter(x=>x['selected']),button, true)
  }
  HandleTaskCustomButtonClick(item, button){
    this.TaskService.HandleTaskCustomButtonClick(item,button,true)
  }

  getPage(page: number) {
    this.paginationValue.page = this.paginationService.paginadellalista(page, this.paginationValue.pagesizeserver);
    const pagetosend = this.paginationService.getPage(page, this.paginationValue.newpagegenerated, this.paginationValue.pagesizeserver);
    if ((pagetosend - 1) % this.paginationValue.pagesizeserver === 0) {
      this.paginationValue.newpagegenerated = page;
      this.Refresh(pagetosend);
    }
  }



}
