
import { Environment as Env } from './BaseEnvironments/Verifica';
import {addModule, getModule} from "./Utility/ModuleUtility";
export var Environment = () => {
  var env= getModule(Env());
  env.apiUrl = 'https://gestionale.verificaspa.it/api';
  // env.apiUrl = 'https://demo.fortyfix.com/store/api';
  env['ProgettiAddon'].CampiOfferta?.forEach(campo => {
    switch (campo.nome) {

      case 'Colore':
        campo.hideif = {fieldexist:'id', fieldnotexist:'id'};
        break;
      case 'Lavorato':
        campo.hideif = {fieldexist:'id', fieldnotexist:'id'};
        break;
      case 'Numero Ordine:':
        campo.hideif = {fieldexist:'id', fieldnotexist:'id'};
        break;
      case 'Completato:':
        campo.hideif = {fieldexist:'id', fieldnotexist:'id'};
        break;
      case 'Annullato:':
        campo.hideif = {fieldexist:'id', fieldnotexist:'id'};
        break;
      case 'Data Annullamento:':
        campo.hideif = {fieldexist:'id', fieldnotexist:'id'};
        break;
      case 'Note Private':
        campo.hideif = {fieldexist:'id', fieldnotexist:'id'};
        break;
      case 'cliente.commerciale':
        campo.hideif = {fieldexist:'id', fieldnotexist:'id'};
        break;
      case 'cliente.segnalatore':
        campo.hideif = {fieldexist:'id', fieldnotexist:'id'};
        break;
    }
  })
  return env

}

export var environment = Environment();

// import {environment as TestEnvironment} from './environment.Verifica_Test';
//
// export const environment = {
//   Wp: false,
//   hasCustomizer: false,
//   production: true,
//   //apiUrl: 'https://www.mypit.cloud:8895/api',
//   //apiUrl: 'https://www.mypit.cloud/verifica/api',
//   apiUrl: 'https://gestionale.verificaspa.it/api',
//   bbbUrl: 'https://newsbs.ddns.net/bigbluebutton',
//   fileUpdate: true,
//   langPrefix: './assets/i18n/',
//   langSuffix: '_Verifica.json',
//   loginbgPath: 'assets/images/background/teamwork-3213924.jpeg',
//   icon: 'assets/images/KlimaIMGxicon.png',
//   baseUrl: 'task/list/RA/TT',
//   href: '/Login',
//   homepages: [
//     {Ruoli: null, RuoliAccesso: [22,29], link: "/progetti/progetti/CA"},
//     {Ruoli: null, RuoliAccesso: [28,12], link: "/task/list/RA/IC"},
//     {Ruoli: null, RuoliAccesso: [31,32,26], link: "/progetti/progetti"}
//   ],
//   logoPath: 'assets/images/Verifica.png',
//   loginMsg: '',
//   logoBg: 'skin6', // six possible values: skin(1/2/3/4/5/6),
//   navbarbg: 'skin2', // six possible values: skin(1/2/3/4/5/6)
//   sidebarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
//   userCircleColor: "",
//   theme: 'light',
//   dir: 'ltr', // two possible values: ltr, rtl
//   layout: 'vertical', // fixed value. shouldn't be changed.
//   sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
//   sidebarpos: 'fixed', // two possible values: fixed, absolute
//   headerpos: 'fixed', // two possible values: fixed, absolute
//   boxed: 'full', // two possible values: full, boxed
//   ClientiType: ['C', 'F'],
//   IDTecnico: "RT",
//   //#region Moduli
//   Modules: {
//     "twoFactorAuth": true,
//     "Admin": true,
//     "Personale": true,
//     "Clienti": true,
//     "Interventi": false,
//     "Prima_Nota": false,
//     "Listini": false,
//     "Progetti": true,
//     "Prodotti": true,
//     "Documenti": true,
//     "Scadenzario": true,
//     "Spedizioni": false,
//     "Stampe": true,
//     "Task": true,
//     "Shared": true,
//     "Dashboard": true,
//     "Settings": true,
//     "Formazione": true,
//     "VersioneCarbotti": false,
//     "PartiProdotto": false,
//     "Parti": false,
//     "Rma": true,
//     "Ecommerce": false,
//     "ScadenzeFiglie": false,
//     "Registrazione": false,
//     "Ticket": true,
//     "Acquisti": false,
//     "taskboard": false,
//     "Camera": true,
//     "Valutazioni": true,
//     "Verifica": true
//   },
//   //#endregion
//   //#region Addon
//   TaskAddon: TestEnvironment.TaskAddon,
//   Personale: {
//     Corsi: false,
//     Attivita: false,
//     File: true,
//     RuoliOperativi:false,
//     CampiAggiuntivi: [
//       {nome:'Abilitazioni', oggetti: ['checks'], type:'checkboxArray', class: 'col-lg-12', modificabile: true  },
//     ]
//   },
//   ClientiAddOn: {
//     codAdhoc: false,
//     enable: false,
//     info: true,
//     commerciale: true,
//     segnalatore: true,
//     segnalatoreInterno: true,
//     sedi: true,
//     TipoSedePrimaria: false,
//     attivita: true,
//     geolocalizzazione: false,
//     ruolo: false,
//     RuoloDefault:{id: "C", label: "Cliente"},
//     Modifica: false,
//     prodotti: false,
//     rma: false,
//     accesso: false,
//     referenti: true,
//     interventi: true,
//     note_private: false,
//     rating: false,
//     ruoliAccesso: false,
//     compilazione: false,
//     luogoNascita: false,
//     dataNascita: false,
//     statoCivile: false,
//     titoloStudio: false,
//     occupazione: false,
//     invalidita: false,
//     legge104: false,
//     diagnosi: false,
//     accompagnamento: false,
//     pensione: false,
//     tutelare: false,
//     medico: false,
//     telMedico: false,
//     terapia: false
//   },
//   ProdottiAddOn: {
//     sottoTitolo: false,
//     shortDescription: false,
//     classeEnergetica: false,
//     tags: false,
//     video: false,
//     dettaglio: false,
//     files: true,
//     cart: false,
//     codice: false,
//     codice_produttore: false,
//     tipo: true,
//     standby: false,
//     tipi: false, // tipi di prodotto (1 - 2 ante klima)
//     cat_image: true,
//     lista_prezzo: true,
//     TranslatedDesc: false,
//     codAdhoc: false,
//     DragSort: false,
//     cat_Sort: false
//   },
//   legenda: [],
//   filtrife: [
//     {id: "T", label: "Tutti", capacita: [], module: undefined},
//     {id: "R", label: "Commesse Splittate", capacita: [], splitBy: "attivita", copyParam:[{fromsplitted: "id", out: "id_attivita",}, 'id', "type", 'acquirente', 'ritiro'], module: "Rma", SubFilter: [
//         {id: "DAP", label: "Da Aprire", capacita: [], module: undefined, arrayEmpty: ['attivita' ]},
//         {id: "DP", label: "Da pianificare", capacita: [], module: undefined, fieldnotexist: ['managerObject' ]},
//         {id: "DA", label: "Da approvare", capacita: [], module: undefined, fieldexist: ['managerObject'], fieldnotexist: ['tempi', 'criticita', 'dispAttrezzature', 'dispPersonale' ]},
//         {id: "DAc", label: "Da accettare", capacita: [], module: undefined, fieldexist: ['managerObject', 'tempi', 'criticita', 'dispAttrezzature', 'dispPersonale', 'data_assegnazione_tec'], fieldnotexist: ['data_assegnazione_tecnico']},
//         {id: "IC", label: "In corso", capacita: [], module: undefined, fieldexist: ['managerObject','tempi', 'criticita', 'dispAttrezzature', 'dispPersonale','data_assegnazione_tec', 'data_assegnazione_tecnico'], fieldnotexist: ['chiuso', 'chkDoc', 'chkMisure', 'chkProcedura', 'chkCoerenza']},
//         {id: "DC", label: "Da chiudere", capacita: [], module: undefined, fieldexist: ['chiuso'], fieldnotexist: ['chkDoc', 'chkMisure', 'chkProcedura', 'chkCoerenza']},
//         {id: "B", label: "Bloccate", capacita: [], module: undefined, fieldexist: ['bloccato']},
//         {id: "C", label:  "Chiuse", capacita: [], module: undefined, fieldexist: ['chkDoc', 'chkMisure', 'chkProcedura', 'chkCoerenza']},
//         {id: "F", label:  "Da Incassare", capacita: [], module: undefined},
//         {id: "TT", label:  "Tutte", capacita: [], module: undefined},
//         {id: "TT", label:  "Tutte", capacita: [], module: undefined},
//       ]},
//     {id: "RA", label: "Commesse", capacita: [], tipo:"R", module: "Rma", SubFilter: [
//         {id: "DAP", label: "Da Aprire", capacita: [], module: undefined},
//         {id: "DP", label: "Da pianificare", capacita: [], module: undefined},
//         {id: "DA", label: "Da approvare", capacita: [], module: undefined},
//         {id: "DAc", label: "Da accettare", capacita: [], module: undefined},
//         {id: "IC", label: "In corso", capacita: [], module: undefined, },
//         {id: "DC", label: "Da chiudere", capacita: [], module: undefined},
//         {id: "B", label: "Bloccate", capacita: [], module: undefined},
//         {id: "C", label:  "Chiuse", capacita: [], module: undefined},
//         {id: "DF", label:  "Da Fatturare", capacita: [], module: undefined},
//         {id: "F", label:  "Da Incassare", capacita: [], module: undefined},
//         {id: "DCA", label:  "Da Chiudere App", capacita: [], module: undefined},
//         {id: "TT", label:  "Tutte", capacita: [], module: undefined},
//       ]},
//     // {id: "A", label: "Attivita", capacita: [], module: undefined},
//     {id: "C", label: "CRM", capacita: [], module: undefined},
//     {id: "W", label: "Visite", capacita: [], module: undefined},
//     {id: "S", label: "Scadenze", capacita: [], module: "Scadenzario"},
//
//     // {id: "F", label: "Fasi", capacita: [], module: undefined},
//     // {id: "L", label: "Lezioni", capacita: [], module: undefined},
//     // {id: "B", label: "Spese", capacita: [], module: undefined},
//     // {id: "I", label: "Fatture", capacita: [], module: undefined},
//     // {id: "V", label: "Valutazioni", capacita: [], module: undefined}
//   ],
//   CalendarAddon: {
//     managerScadenza: true,
//   },
//   PartiAddOn: [false],//  categorie associate
//   RmaAddOn: TestEnvironment.RmaAddOn,
//   ProgettiAddon: {
//     commerciale: true,
//     segnalatore: true,
//     categoriaVoceDiCosto: true,
//     lavorato: false,
//     sottoOfferte: true,
//     hideAdd: true,
//     consegna: true,
//     ReferenteOfferta: true,
//     ReferenteSottoofferta: true,
//     ProvaAcquisto: true, //impianto
//     HideVoci: true,
//     rma: true,
//     Revisioni: false,
//     genericFiles: true,
//     PulsantiLista: [
//       {nome: 'Importa da Gamma', stato: ["CC","CA"], class:'btn btn-orange rounded-pill py-2',labelClass:'ml-1 text-white font-weight-bold', type: 'GET', restRoute: '/progetti/progetti/import', capacita: ['gestione-progetti'], icon:'fas fa-download', preMex: "Attenzione l'opreazione potrebbe richiedere alcuni minuti", successMex: "Commesse importate con successo"}
//     ],
//     CampiNuovoCliente: [
//       {nome:'cliente.nominativo', prevDivClass:"col-12", required: true, oggetti: ['nominativo'], modificabile: true, inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
//       {nome:'cliente.piva', required: false, oggetti: ['piva'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
//       {nome:'cliente.pec', required: false, oggetti: ['pec'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
//       {nome:'cliente.email', requiredif: {fieldexist: 'username'}, oggetti: ['email'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
//       {nome:'cliente.indirizzo', required: false, oggetti: ['indirizzo'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
//       {nome:'cliente.cap', required: false, oggetti: ['cap'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
//       {nome:'cliente.citta', required: false, oggetti: ['citta'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
//       {nome:'cliente.provincia', required: false, oggetti: ['provincia'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
//       {nome:'cliente.telefono', required: false, oggetti: ['telefono'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
//       {nome:'cliente.referente.nome', required: false, oggetti: ['referenteObject'], field:['nome'], type:'object', modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
//       {nome:'cliente.referente.cognome', nextDivClass:"col-12", required: false, oggetti: ['referenteObject'], type:'object', field:['cognome'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
//       {nome:'cliente.referente.telefono', nextDivClass:"col-12", required: false, oggetti: ['referenteObject'], type:'object', field:['telefono'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
//       {nome:'cliente.referente.email', nextDivClass:"col-12", required: false, oggetti: ['referenteObject'], type:'object', field:['email'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
//     ],
//     CampiOfferta: [
//       {
//         modificabile: true,
//         nome: 'Offerta.Responsabile',
//         oggetti: ['manager'],
//         inputType: 'singleDropdown',
//         source: 'managers',
//         internalOutput: 'managerObject',
//         OnFilter: 'filtroManager',
//         OnChange: 'OnManagerChange',
//         placeholder: 'Seleziona',
//         settings: {
//           singleSelection: true,
//           closeDropDownOnSelection: true,
//           singleOutput: true,
//           idField: 'id',
//           textField: 'fullName',
//           searchPlaceholderText: "inserire almeno 3 caratteri",
//           allowSearchFilter: true,
//           allowRemoteDataSearch: true,
//         },
//         titleClass: 'control-label col-form-label',
//         class: 'col-lg-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true
//       },
//       {
//         modificabile: true,
//         nome: 'Compilatore',
//         oggetti: ['commerciale'],
//         inputType: 'singleDropdown',
//         source: 'managers',
//         internalOutput: 'commerciale',
//         OnFilter: 'filtroCommerciale',
//         placeholder: 'Seleziona',
//         settings: {
//           singleSelection: true,
//           closeDropDownOnSelection: true,
//           singleOutput: true,
//           idField: 'id',
//           textField: 'fullName',
//           searchPlaceholderText: "inserire almeno 3 caratteri",
//           allowSearchFilter: true,
//           allowRemoteDataSearch: true,
//         },
//         titleClass: 'control-label col-form-label',
//         class: 'col-lg-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true
//       },
//       // {
//       //   modificabile: true,
//       //   nome: 'cliente.segnalatore',
//       //   oggetti: ['clienti'],
//       //   inputType: 'singleDropdown',
//       //   source: 'clienti',
//       //   internalOutput: 'segnalatore',
//       //   OnFilter: 'filtrosegnalatore',
//       //   placeholder: 'Seleziona',
//       //   settings: {
//       //     singleSelection: true,
//       //     closeDropDownOnSelection: true,
//       //     singleOutput: true,
//       //     idField: 'id',
//       //     textField: 'nominativo',
//       //     searchPlaceholderText: "inserire almeno 3 caratteri",
//       //     allowSearchFilter: true,
//       //     allowRemoteDataSearch: true,
//       //     noDataAvailablePlaceholderText: "Nessun Cliente trovato ",
//       //     moreButton: false,
//       //     moreButtonText: "Cerca in AdHoc",
//       //   },
//       //   titleClass: 'control-label col-form-label',
//       //   class: 'col-lg-3',
//       //   visibleifProhibited: true
//       // },
//       // {
//       //   modificabile: true,
//       //   nome: 'Colore',
//       //   oggetti: ['colore'],
//       //   inputType: 'color',
//       //   titleClass: 'control-label col-form-label',
//       //   class: 'col-lg-1',
//       //   visibleifProhibited: true
//       // },
//       // {
//       //   modificabile: true,
//       //   nome: 'Lavorato',
//       //   oggetti: ['lavorato'],
//       //   inputType: 'text',
//       //   titleClass: 'control-label col-form-label',
//       //   class: 'col-lg-1',
//       //   visibleifProhibited: true
//       // },
//       {
//         modificabile: true,
//         nome: 'Oggetto:',
//         oggetti: ['oggetto'],
//         inputType: 'text',
//         titleClass: 'control-label col-form-label',
//         class: 'col-lg-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true      },
//       {
//         modificabile: true,
//         nome: 'Preventivo N:',
//         oggetti: ['codice'],
//         inputType: 'text',
//         titleClass: 'control-label col-form-label',
//         class: 'col-lg-2',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true      },
//       {
//         modificabile: true,
//         nome: 'Commessa N:',
//         oggetti: ['codiceContratto'],
//         inputType: 'text',
//         titleClass: 'control-label col-form-label',
//         class: 'col-lg-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true      },
//       {
//         nome: 'Contratto Caricato',
//         modificabile: true,
//         oggetti: ['caricatoContratto'],
//         inputType: 'checkbox',
//         titleClass: 'control-label col-form-label',
//         class: 'col-lg-2',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true
//       },
//       {
//         prevDivClass: "col-md-12",
//         modificabile: true,
//         nome: 'Offerta.Creazione',
//         oggetti: ['data_creazione'],
//         inputType: 'date',
//         titleClass: 'control-label col-form-label',
//         class: 'col-md-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true      },
//       {
//         modificabile: true,
//         nome: 'Offerta.Offerta',
//         oggetti: ['data_emissione'],
//         inputType: 'date',
//         titleClass: 'control-label col-form-label',
//         class: 'col-md-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true      },
//       {
//         modificabile: true,
//         nome: 'Offerta.Accettazione',
//         oggetti: ['data_accettazione'],
//         inputType: 'date',
//         titleClass: 'control-label col-form-label',
//         class: 'col-md-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true      },
//       {
//         modificabile: true,
//         nome: 'Offerta.Conferma',
//         oggetti: ['data_conferma'],
//         inputType: 'date',
//         titleClass: 'control-label col-form-label',
//         class: 'col-md-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true      },
//       {
//         modificabile: true,
//         nome: 'Note',
//         oggetti: ['note'],
//         inputType: 'textarea',
//         titleClass: 'control-label col-form-label',
//         class: 'col-md-6',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true
//       },
//
//     ],
//     CampiSottoOfferta: [
//       {
//         prevDivClass: 'col-12',
//         modificabile: false,
//         nome: 'Esercente',
//         oggetti: ['Esercente'],
//         inputType: 'text',
//         titleClass: ' card-title',
//         titleStyle:{ fontSize:'16px'},
//         class: 'col-md-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true      },
//       {
//         prevDivClass: 'col-12',
//         modificabile: true,
//         nome: 'Nominativo Esercente',
//         oggetti: ['consegna'],
//         field: ['nominativo'],
//         inputType: 'text',
//         type:'object',
//         titleClass: 'control-label col-form-label',
//         class: 'col-md-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true      },
//       {
//         modificabile: true,
//         nome: 'Indirizzo Esercente',
//         oggetti: ['consegna'],
//         field: ['indirizzo'],
//         inputType: 'text',
//         type:'object',
//         titleClass: 'control-label col-form-label',
//         class: 'col-md-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true      },
//       {
//         modificabile: true,
//         nome: 'CAP Esercente',
//         oggetti: ['consegna'],
//         field: ['cap'],
//         inputType: 'text',
//         type:'object',
//         titleClass: 'control-label col-form-label',
//         class: 'col-md-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true      },
//       {
//         modificabile: true,
//         nome: 'Città Esercente',
//         oggetti: ['consegna'],
//         field: ['citta'],
//         inputType: 'text',
//         type:'object',
//         titleClass: 'control-label col-form-label',
//         class: 'col-md-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true      },
//       {
//         modificabile: true,
//         nome: 'Provincia Esercente',
//         oggetti: ['consegna'],
//         field: ['provincia'],
//         inputType: 'text',
//         type:'object',
//         titleClass: 'control-label col-form-label',
//         class: 'col-md-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true      },
//       {
//         prevDivClass: 'col-12',
//         modificabile: true,
//         nome: 'Nominativo Referente',
//         oggetti: ['referente'],
//         field: ['nominativo'],
//         type:'object',
//         inputType: 'text',
//         titleClass: 'control-label col-form-label',
//         class: 'col-md-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true      },
//       {
//         modificabile: true,
//         nome: 'Telefono Referente',
//         oggetti: ['referente'],
//         field: ['telefono'],
//         type:'object',
//         inputType: 'text',
//         titleClass: 'control-label col-form-label',
//         class: 'col-md-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true      },
//       {
//         modificabile: true,
//         nome: 'Email Referente',
//         oggetti: ['referente'],
//         field: ['email'],
//         type:'object',
//         inputType: 'text',
//         titleClass: 'control-label col-form-label',
//         class: 'col-md-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true      },
//       {
//         modificabile: true,
//         nome: 'Attività impresa',
//         oggetti: ['oggetto'],
//         inputType: 'text',
//         titleClass: 'control-label col-form-label',
//         class: 'col-md-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true      },
//       {
//         modificabile: true,
//         nome: 'Preventivo',
//         oggetti: ['prezzo'],
//         inputType: 'euro',
//         titleClass: 'control-label col-form-label',
//         class: 'col-md-3',
//         allowedCapacity: ['gestione-progetti'],
//         visibleifProhibited: true      },
//       // {
//       //   prevDivClass: 'col-12',
//       //   modificabile: true,
//       //   nome: 'Note',
//       //   oggetti: ['note'],
//       //   inputType: 'textarea',
//       //   titleClass: 'control-label col-form-label',
//       //   class: 'col-md-6',
//       //   visibleifProhibited: true
//       // },
//       // {
//       //   modificabile: true,
//       //   nome: 'Note Private',
//       //   oggetti: ['note_private'],
//       //   inputType: 'textarea',
//       //   titleClass: 'control-label col-form-label',
//       //   class: 'col-md-6',
//       //   visibleifProhibited: false
//       // },
//     ],
//     CampiSottoOffertaPreventivo: [// se pieno viene gestito preventivo
//       {
//         sezione:'Esercente',
//         campi:[
//           {
//             prevDivClass: 'col-12',
//             modificabile: true,
//             nome: 'Nominativo',
//             oggetti: ['consegna'],
//             field: ['nominativo'],
//             inputType: 'text',
//             type:'object',
//             titleClass: 'control-label col-form-label',
//             class: 'col-md-3',
//             allowedCapacity: ['gestione-progetti'],
//             visibleifProhibited: true          },
//           {
//             modificabile: true,
//             nome: 'Indirizzo',
//             oggetti: ['consegna'],
//             field: ['indirizzo'],
//             inputType: 'text',
//             type:'object',
//             titleClass: 'control-label col-form-label',
//             class: 'col-md-3',
//             allowedCapacity: ['gestione-progetti'],
//             visibleifProhibited: true          },
//           {
//             modificabile: true,
//             nome: 'CAP',
//             oggetti: ['consegna'],
//             field: ['cap'],
//             inputType: 'text',
//             type:'object',
//             titleClass: 'control-label col-form-label',
//             class: 'col-md-3',
//             allowedCapacity: ['gestione-progetti'],
//             visibleifProhibited: true          },
//           {
//             modificabile: true,
//             nome: 'Città',
//             oggetti: ['consegna'],
//             field: ['citta'],
//             inputType: 'text',
//             type:'object',
//             titleClass: 'control-label col-form-label',
//             class: 'col-md-3',
//             allowedCapacity: ['gestione-progetti'],
//             visibleifProhibited: true          },
//           {
//             modificabile: true,
//             nome: 'Provincia',
//             oggetti: ['consegna'],
//             field: ['provincia'],
//             inputType: 'text',
//             type:'object',
//             titleClass: 'control-label col-form-label',
//             class: 'col-md-3',
//             allowedCapacity: ['gestione-progetti'],
//             visibleifProhibited: true          },
//         ]
//       },
//       {
//         sezione: 'Impianto',
//         campi: [
//
//           {
//             modificabile: true,
//             nome: 'Nominativo Referente',
//             oggetti: ['referente'],
//             field: ['nominativo'],
//             type:'object',
//             inputType: 'text',
//             titleClass: 'control-label col-form-label',
//             class: 'col-md-3',
//             allowedCapacity: ['gestione-progetti'],
//             visibleifProhibited: true          },
//           {
//             modificabile: true,
//             nome: 'Telefono Referente',
//             oggetti: ['referente'],
//             field: ['telefono'],
//             type:'object',
//             inputType: 'text',
//             titleClass: 'control-label col-form-label',
//             class: 'col-md-3',
//             allowedCapacity: ['gestione-progetti'],
//             visibleifProhibited: true          },
//           {
//             modificabile: true,
//             nome: 'Email Referente',
//             oggetti: ['referente'],
//             field: ['email'],
//             type:'object',
//             inputType: 'text',
//             titleClass: 'control-label col-form-label',
//             class: 'col-md-3',
//             allowedCapacity: ['gestione-progetti'],
//             visibleifProhibited: true          },
//           {
//             modificabile: true,
//             nome: 'Attività impresa',
//             oggetti: ['oggetto'],
//             inputType: 'text',
//             titleClass: 'control-label col-form-label',
//             class: 'col-md-3',
//             allowedCapacity: ['gestione-progetti'],
//             visibleifProhibited: true          },
//           { prevDivClass: "col-12", modificabile: true, nome: 'Indirizzo', oggetti: ['provaAcquisto'], field:['indirizzo'], inputType: 'text', titleClass: 'control-label col-form-label', class: 'col-lg-3', type:'object', allowedCapacity: ['gestione-progetti'],
//             visibleifProhibited: true},
//           {modificabile: true, nome: 'Cap', oggetti: ['provaAcquisto'], field:['cap'], inputType: 'text', titleClass: 'control-label col-form-label', class: 'col-lg-3', type:'object', visibleifProhibited: true, allowedCapacity: ['gestione-progetti']},
//           {modificabile: true, nome: 'Città', oggetti: ['provaAcquisto'], field:['citta'], inputType: 'text', titleClass: 'control-label col-form-label', class: 'col-lg-3', type:'object', visibleifProhibited: true, allowedCapacity: ['gestione-progetti']},
//           {modificabile: true, nome: 'Provincia', oggetti: ['provaAcquisto'], field:['provincia'], inputType: 'text', titleClass: 'control-label col-form-label', class: 'col-lg-3', type:'object', visibleifProhibited: true, allowedCapacity: ['gestione-progetti']},
//           {nome:'Attività', oggetti: ['attivita'], type:'checkboxArrayMEXC', class: 'col-lg-12', modificabile: true , allowedCapacity: ['gestione-progetti'], visibleifProhibited: true},
//           {modificabile: true, nome: 'Prezzo', oggetti: ['prezzo'], inputType: 'number', titleClass: 'control-label col-form-label', class: 'col-lg-3', visibleifProhibited: true, allowedCapacity: ['gestione-progetti']},
//         ]
//       },
//
//     ],
//     CampiVociPreventivo: [
//       {nome:'Impianto', oggetti:['provaAcquisto','provaAcquisto','provaAcquisto','provaAcquisto'], field: ['indirizzo', 'cap', 'citta', 'provincia'], suffix:' ', type: 'object'},
//       {nome:'Esercente', oggetti:['consegna','consegna','consegna','consegna','consegna'], field: ['nominativo','indirizzo', 'cap', 'citta', 'provincia'], suffix:' ', type: 'object'},
//       {nome:'Prezzo €', oggetti:['prezzo'], inputType: 'euro'},
//     ]
//   },
//   Documenti: {
//     campiDocumento: [
//       {
//         modificabile: true,
//         nome: 'Descrizione',
//         oggetti: ['descrizione'],
//         inputType: 'text',
//         titleClass: 'control-label col-form-label',
//         class: 'col-lg-12',
//         visibleifProhibited: true
//       },
//       {
//         modificabile: true,
//         nome: 'File',
//         oggetti: [null],
//         inputType: 'file',
//         placeholder: '(pdf,doc,docx,xls,xlsx,jpg,png,txt,ods,odt) Size Limit: 5.00 MB',
//         visibleifProhibited: true
//       },
//       {
//         modificabile: true,
//         nome: 'Categorie',
//         oggetti: ['categorie'],
//         inputType: 'singleDropdown',
//         source: 'CategorieLineare',
//         OnFilter: 'filtroCategoria',
//         internalOutput: 'Categorie',
//         hideif:{fieldexist:'id'},
//         placeholder: 'Seleziona',
//         settings: {
//           singleSelection: false,
//           idField: 'id',
//           textField: 'categoria',
//           searchPlaceholderText: "inserire almeno 3 caratteri",
//           allowSearchFilter: true,
//           allowRemoteDataSearch: true,
//         },
//         class: 'col-lg-12',
//         visibleifProhibited: true
//       },
//       {nome:'_log_', oggetti: ['checks'], type:'checkboxArray', class: 'col-lg-12', source:'/rma/rma/attivita', modificabile: true },
//
//     ]
//   },
//   //#endregion
//
//   PersoneMultiSelect: {
//     singleSelection: false,
//     closeDropDownOnSelection: true,
//     idField: 'id',
//     textField: 'fullName',
//     searchPlaceholderText: "inserire almeno 3 caratteri",
//     allowSearchFilter: true,
//     allowRemoteDataSearch: true,
//   },
//   //#region Menu
//   Menu: [
//     {
//       title: "Menu",
//       class: "has-arrow",
//       label: "Menu",
//       extralink: true
//     },
//     {
//       module: "Personale",
//       path: "/listautenti/personale",
//       title: 'menu.PersonaleLabel',
//       icon: "far fa-user",
//       capacita: ["menu-persone"]
//     },
//     {
//       module: "Clienti",
//       path: "/listautenti/aziende",
//       title: 'menu.clienti',
//       icon: "mdi mdi-account-multiple",
//       capacita: ["menu-clienti"]
//     },
//     {
//       module: "Progetti",
//       path: "progetti/progetti",
//       title: 'menu.progetti',
//       class: "has-arrow",
//       icon: "mdi mdi-calendar-multiple-check",
//       submenu: [
//         {
//           path: "progetti/progetto",
//           title: 'Nuova offerta',
//           icon: "mdi mdi-calendar-multiple-check",
//           capacita: ["menu-nuova-offerta"]
//         },
//         {
//           module: "Progetti",
//           path: "progetti/progetti/CR",
//           title: 'Da inviare',
//           icon: "mdi mdi-calendar",
//           capacita: ["menu-offerte-dainviare"]
//         },
//         {
//           module: "Progetti",
//           path: "progetti/progetti/EM",
//           title: 'Da accettare',
//           icon: "mdi mdi-calendar",
//           capacita: ["menu-offerte-inviate"]
//         },
//         {
//           module: "Progetti",
//           path: "progetti/progetti/AC",
//           title: 'Accettate',
//           icon: "mdi mdi-calendar",
//           capacita: ["menu-offerte-accettate"]
//         },
//         {
//           module: "Progetti",
//           path: "progetti/progetti",
//           title: 'Tutte',
//           icon: "mdi mdi-calendar",
//           capacita: ["menu-progetti"]
//         },
//       ],
//       capacita: ["menu-progetti", "menu-offerte-dainviare", "menu-offerte-inviate", "menu-offerte-accettate"]
//     },
//     {
//       module: "Progetti",
//       path: "progetti/progetti",
//       title: 'Contratti',
//       class: "has-arrow",
//       icon: "mdi mdi-calendar-multiple-check",
//       submenu: [
//         {
//           module: "Progetti",
//           path: "progetti/progetti/CA",
//           title: 'Aperti',
//           icon: "mdi mdi-calendar",
//           capacita: ["menu-contratti"]
//         },
//         {
//           module: "Progetti",
//           path: "progetti/progetti/CC",
//           title: 'Tutti',
//           icon: "mdi mdi-calendar",
//           capacita: ["menu-contratti"]
//         },
//       ],
//       capacita: ["menu-contratti"]
//     },
//     // {
//     //   module: "Task",
//     //   path: "task/unita",
//     //   title: 'Calendario',
//     //   icon: "mdi mdi-calendar",
//     // },
//     {
//       module: "Rma",
//       title: 'Commesse',
//       icon: "mdi mdi-calendar-multiple-check",//mdi-calendar-multiple-check //mdi-engine-outline
//       class: "has-arrow",
//       submenu: [
//         {
//           module: "Rma",
//           path: "task/list/RA/DAP",
//           title: 'Da Aprire',
//           icon: "mdi mdi-calendar",
//           capacita: ["menu-commesse-daaprire"]
//         },
//         {
//           module: "Rma",
//           path: "task/list/RA/DP",
//           title: 'Da pianificare',
//           icon: "mdi mdi-calendar",
//           capacita: ["menu-commesse-dapianificare"]
//         },
//         {
//           module: "Rma",
//           path: "task/list/RA/DA",
//           title: 'Da approvare',
//           icon: "mdi mdi-calendar",
//           capacita: ["menu-commesse-daapprovare"]
//         },
//         {
//           module: "Rma",
//           path: "task/list/RA/DAc",
//           title: "Da accettare",
//           icon: "mdi mdi-calendar",
//           capacita: ["menu-commesse-daaccettare"]
//         },
//         {
//           module: "Rma",
//           path: "task/list/RA/IC",
//           title: 'In corso',
//           icon: "mdi mdi-calendar",
//           capacita: ["menu-commesse-incorso"]
//         },
//         {
//           module: "Rma",
//           path: "task/list/RA/DC",
//           title: 'Da chiudere',
//           icon: "mdi mdi-calendar",
//           capacita: ["menu-commesse-dachiudere"]
//         },
//         {
//           module: "Rma",
//           path: "task/list/RA/B",
//           title: 'Bloccate',
//           icon: "mdi mdi-calendar",
//           capacita: ["menu-commesse-bloccate"]
//         },
//         {
//           module: "Rma",
//           path: "task/list/RA/C",
//           title: 'Chiuse',
//           icon: "mdi mdi-calendar",
//           capacita: ["menu-commesse-chiuse"]
//         },
//         {
//           module: "Rma",
//           path: "task/list/RA/DF",
//           title: 'Da Fatturare',
//           icon: "mdi mdi-calendar",
//           capacita: ["menu-fatture"]
//         },
//         {
//           module: "Rma",
//           path: "task/list/RA/F",
//           title: 'Da Incassare',
//           icon: "mdi mdi-calendar",
//           capacita: ["menu-fatture"]
//         },
//         {
//           module: "Rma",
//           path: "task/list/RA/TT",
//           title: 'Tutte',
//           icon: "mdi mdi-calendar",
//           capacita: ["menu-commesse-tutte"]
//         },
//       ],
//       capacita: [
//         "menu-commesse-tutte",
//         "menu-commesse-daaprire",
//         "menu-commesse-dapianificare",
//         "menu-commesse-daapprovare",
//         "menu-commesse-daaccettare",
//         "menu-commesse-incorso",
//         "menu-commesse-dachiudere",
//         "menu-commesse-bloccate",
//         "menu-commesse-chiuse",
//         "menu-fatture"
//       ]
//     },
//     {
//       module: "Documenti",
//       path: "progetti/documenti",
//       title: 'menu.DocumentiLabel',
//       icon: "mdi mdi-file-document",
//       capacita: ["menu-documenti"]
//     },
//     {
//       module: "Stampe",
//       path: "stampe/templates",
//       title: "menu.StampeLabel",
//       icon: "mdi mdi-printer",
//       class: "has-arrow",
//       capacita: ["menu-stampa", "menu-stampa-blocchi"],
//       submenu: [
//         {
//           path: "stampe/templates",
//           title: "Templates",
//           capacita: ["gestione-stampa"]
//         },
//         {
//           path: "stampe/marker",
//           title: "Marker",
//           capacita: ["menu-stampa"]
//         },
//         {
//           path: "/blocchi/blocchi/list",
//           title: "Blocchi",
//           capacita: ["menu-stampa-blocchi"]
//         }
//       ]
//     },
//     {
//       module: "Settings",
//       path: "settings/settings",
//       title: "menu.SettingsLabel",
//       icon: "mdi-settings",
//       capacita: ["menu-settings"]
//     },
//     {
//       module: "Settings",
//       path: "settings/smtp",
//       title: "menu.smtp",
//       icon: "mdi-settings",
//       capacita: ["menu-settings"]
//     },
//     {
//       module: "Admin",
//       path: "listautenti/roles",
//       title: "Ruoli",
//       icon: "mdi mdi-lock-open-outline",
//       capacita: ["menu-ruoli"]
//     },
//   ],
//   //#endregion
// };
